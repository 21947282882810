import React from 'react';
import "./pages.css";


export default function Page404() {

  return (
    <div className="404-container">
        <h1>404 Not Found</h1>

    </div>
  );
}
